/* reused styles */

body{
  background-color: white;
  font-family: 'Montserrat', sans-serif;
  color: #2b4a67;
  line-height: 25px;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none; /* no underline */
}

#amazon-mobile{
  display: none;
}

@media (max-width: 600px) {
  #amazon-mobile{
    display: block;
      padding-top: 50px;
      width: 100%;
  }
}
.content{
  width: 95%;
  margin: 0 auto;
  max-width: 1200px;
}

@media (max-width: 600px) {
  .base{
    display: none;
  }
  .gallery{
    padding-top: 37px;
  }

  .column-1 {
    display:none;
    position: fixed;
  }

  .column-2 {
    width: 100%;
  }

  .content {
    width: 95%;
    margin: 0 auto;
  }

  #about{
    margin-top: 0px !important;
    padding: 50px 15px 0px 15px !important;
  }

  #profile-pic {
    width: 100% !important;
    margin: 10px 0px 20px 0px !important;
  }

}

.title{
  text-align: center;
  line-height: 10px;
  font-size: 14px;
}

@media (max-width: 480px) {
  #home-img{
    width: 100%;
  }
}
@media (min-width: 480px) {
  #home-img{
    width: 70%;
    padding-top: 8px;
    display: block;
    margin: 0 auto;
  }
}

#header{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

#logo{
  width: 60%;
}

#menu{
  /* position: relative; */
  margin-top: 55px;
}

#contact{
  margin-top: 20px;
}

#footer{
  position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   background-color: white;
   color: #2b4a67;
   text-align: center;
   height: 50px;
}

#about{
  margin-top: 30px;
  text-align: justify;
  line-height: 25px;
}

#profile-pic{
  width: 30%;
  float: left;
  margin-right: 25px;
}

#contact-form{
  padding-top: 100px;
}

hr{
  border-top: 1px solid #eaeaea;;
}


