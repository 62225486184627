.selected-img-desc{
  position: inherit;
  transform: translate(0px, 558px);
  width: 99%;
  padding-left: 5px;
  font-size: 12px;
  color: #ffffffb0;
  text-align: center;
}
.embla {
  position: relative;
  background-color: #f5f5f6;
  padding: 20px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.embla__viewport {
  overflow: hidden;
  // width: 100%;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin-left: -10px;
  
}

.embla__slide {
  position: relative;
  padding-left: 10px;
  
}

.embla__slide {
  min-width: 100%;
  height: 400px;
  @media (min-width: 600px) {
    height: 600px;
    min-width: 50%;
  }
}



.embla__slide:nth-child(1) {
  img{
    transform: translate(-51%, -65%);
    @media (min-width: 600px) {
      transform: translate(-51%, -56%);
    }
    width: 100%;
    height: auto;
  }
}
.embla__slide:nth-child(2) {
  img{
    transform: translate(-50%, -68%);
    @media (min-width: 600px) {
      transform: translate(-50%, -50%);
    }
  }
}

.embla__slide:nth-child(3) {
  img{
    @media (max-width: 600px) {
    transform: translate(-50%, -64%);
    height: 370px;
    }
    @media (min-width: 600px) {
      transform: translate(-50%, -50%);
    }
  }
}

.embla__slide:nth-child(4) {
  img{
    transform: translate(-51%, -68%);
    @media (min-width: 600px) {
      transform: translate(-51%, -50%);
    }
  }
}

.embla__slide:nth-child(5) {
  @media (max-width: 600px) {
    img{
      transform: translate(-50%, -64%);
      height: 450px;
    }
  }
  @media (min-width: 600px) {
    img{transform: translate(-61%, -50%);}
  }
}

.embla__slide__inner {
  position: relative;
  overflow: hidden;
  height: 500px;
  @media (min-width: 600px) {
    height: 600px;
  }

}


.embla__slide__img {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  // width: 100%;
  height: 300px;
  @media (min-width: 900px) {
    height: 600px;
  }
  // min-height: 100%;
  // min-width: 100%;
  max-width: none;
  // transform: translate(-50%, -50%);
}


.embla__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  fill: #1bcacd;
  padding: 0;
}

.embla__button:disabled {
  cursor: default;
  opacity: 0.3;
}

.embla__button__svg {
  width: 100%;
  height: 100%;
}

.embla__button--prev {
  left: 27px;
}

.embla__button--next {
  right: 27px;
}
