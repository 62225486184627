form {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    .span-half{
        width: 50%;
        float: left;
        label,input {
            display: block;
        }
    }
    .span-full{
        width: 100%;
    }
    input, textarea{
        border: 1px solid rgb(227, 225, 225);
        background: #fafafa;
        margin: 10px 0px;
        outline: none;

        :focus {
            outline: none;
      }
    }
    ::placeholder {
        color:  rgb(194, 192, 192);
        font-size: 12px;
        padding-left: 10px;
        font-family: 'Montserrat', sans-serif;
    }
    input{
        height: 40px;
        :focus {
            outline: none;
      }
    }
    .submit-btn{
        display: block;
        // margin: 0 auto;
        background-color: #113d51;
        border-radius: 25px;
        padding: 15px;
        width: 200px;
        text-align: center;
        border: none;
        span{
            color: white;
            text-transform: uppercase;
            font-size: 14px;
        }
        :focus {
            outline: none;
      }
    }
}

// subscription email form
#subscribe-form {
    
    #email-subscription{
        width: 250px;
        @media (min-width: 450px) {
            margin-right: 20px;
          }
        
    }

    .subscribe-btn {
        background-color: #113d51;
        border-radius: 25px;
        padding: 15px;
        width: 200px;
        text-align: center;
        border: none;
        span{
            color: white;
            text-transform: uppercase;
            font-size: 14px;
        }
        :focus {
            outline: none;
      }
    }
}